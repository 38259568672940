<template>
  <div>
    <el-carousel height="150px" style="padding-top: 5px">
        <el-carousel-item><img :src="require('@/assets/book/cover1.jpg')"></el-carousel-item>
        <el-carousel-item><img :src="require('@/assets/book/cover2.jpg')"></el-carousel-item>
        <el-carousel-item><img :src="require('@/assets/book/cover3.jpg')"></el-carousel-item>
    </el-carousel>
<div class="title-box">
  <strong class="label"> </strong>
  <p class="title">英语学习</p>
</div>
    <div class="book-container">
      <!--      <BookItem v-for="item in books" :key="item.index" :BookItem = item ></BookItem>-->
      <div class="book-detail" v-for="item in books" :key="item.index" :BookItem=item >
        <!--      <div class="book-detail" @click="goToDetail()">-->
        <div class="book-item">
          <img :src="item.imgLink" class="book-img" @click="goToBookDetail(item)">
          <span>{{ item.name }}</span>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import {bookList} from '/src/data/data.js'
// import BookItem from "@/components/BookItem";

export default {
  name: 'BookList',
  // components: {BookItem},
  // components: {BookItem},
  props: {},
  data() {
    return {
      books: bookList,
    }
  },
  methods:{
    goToBookDetail(item){
      console.log("跳转前")
      console.log(item)
      this.$router.push({name: 'BookDetail', params: {bookId: item.index}})
    }
  },
  created() {
    console.log(this.books);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.book-container {
  display: flex;
  align-content: flex-start;
  flex-flow: row wrap;
}
.title-box{
  padding: 12px 12px;
  font-family: PingFangSC-Semibold,serif;
  display: flex;
}
.label{
  display: inline-block;
  width: .2rem;
  border-radius: .1rem;
  background: #fe5105;
  margin-right: .3rem;
}
.title{
  color: #343c44;
  font-size: .8rem;
  line-height: 1rem;
  overflow: hidden;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.book-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}
.book-img {
  width: 96px;
  height: 96px;
  border: .05rem solid #e2e2e2;
}

.book-detail{
  flex: 0 0 30%;
  margin-bottom: 20px;
  padding-left: 5px;
  padding-right: 5px;
}
</style>
